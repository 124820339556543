<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="二维码ID：">
                <el-input size="small" v-model="searchForm.sn" placeholder="请输入二维码ID"></el-input>
            </el-form-item>
            <el-form-item label="店铺名称">
                <el-input size="small" v-model="searchForm.name" placeholder="请输入店铺名称"></el-input>
            </el-form-item>
            <el-form-item label="商家电话：">
                <el-input size="small" v-model="searchForm.mobile" placeholder="请输入商家电话"></el-input>
            </el-form-item>
            <el-form-item label="状态：">
                <el-select v-model="searchForm.status" size="small" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="待审核" :value="0"></el-option>
                    <!-- <el-option label="待发货" :value="1"></el-option> -->
                    <!-- <el-option label="已发货" :value="2"></el-option> -->
                    <el-option label="平台待审核" :value="1"></el-option>
                    <el-option label="平台审核成功" :value="2"></el-option>
                    <el-option label="已完成" :value="3"></el-option>
                    <el-option label="已驳回" :value="-2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="申请时间：">
                <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="15px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="sn" label="二维码ID" align="center" width="200"></el-table-column>
            <el-table-column prop="name" label="店铺名称" align="center" width="200"></el-table-column>
            <el-table-column prop="mobile" label="商家电话" align="center" width="150"></el-table-column>
            <el-table-column prop="company_name" label="商家名称/公司名称" align="center" width="200"></el-table-column>
            <el-table-column prop="facilitator_name" label="运营商姓名" align="center" width="150"></el-table-column>
            <el-table-column prop="facilitator_mobile" label="运营商电话" align="center" width="150"></el-table-column>
            <el-table-column prop="rate" label="商家申请费率比例" align="center" width="150">
                <template v-slot="{ row }">{{ row.rate }}%</template>
            </el-table-column>
            <el-table-column prop="facilitator_rate" label="运营商费率比例" align="center" width="150">
                <template v-slot="{ row }">{{ row.facilitator_rate }}%</template>
            </el-table-column>

            <el-table-column prop="create_time" label="申请时间" align="center" width="200">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center" fixed="right" width="150">
                <template v-slot="{ row }">
                    <span :style="{ color: row.status == 0 ? '#e6a23c' : row.status == -2 ? '#f56c6c' : '' }">
                        {{ row.status == 0 ? '运营商审核' : row.status == 1 ? '平台审核' : row.status == 2 ? '已通过' : row.status == 3
                            ? '已完成' : '平台驳回' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="150">
                <template v-slot="{ row }">
                    <el-button @click="(showImage = !0), (lookinfo = row)" type="text">查看</el-button>
                    <el-button @click="audit(row)" type="text" v-if="row.status == 0 || row.status == 1">审核</el-button>
                    <el-button @click="(showReject = !0), (refuse_reason = row.refuse_reason)" type="text"
                        v-if="row.status == -2">驳回原因</el-button>
                    <el-button @click="auditEdit(row)" type="text" v-if="row.status == 2">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
        <el-dialog title="查看" :visible.sync="showImage" width="650px">
            <el-form label-width="180px" class="see-image">
                <el-form-item label="是否是法人：">
                    <div>{{ lookinfo.is_juridical ? '是' : '否' }}</div>
                </el-form-item>
                <el-form-item label="非法人身份证：" v-if="!lookinfo.is_juridical">
                    <el-image v-for="(item, index) in [lookinfo.identity_card_z, lookinfo.identity_card_f]" fit="cover"
                        :key="index" :src="item"
                        :preview-src-list="[lookinfo.identity_card_z, lookinfo.identity_card_f]"></el-image>
                </el-form-item>
                <el-form-item label="法人身份证：">
                    <el-image v-for="(item, index) in [lookinfo.id_card_image_z, lookinfo.id_card_image_f]" fit="cover"
                        :key="index" :src="item"
                        :preview-src-list="[lookinfo.id_card_image_z, lookinfo.id_card_image_f]"></el-image>
                </el-form-item>
                <el-form-item label="授权书照片：" v-if="!lookinfo.is_juridical">
                    <el-image fit="cover" :src="lookinfo.authorization"
                        :preview-src-list="[lookinfo.authorization]"></el-image>
                </el-form-item>
                <el-form-item label="营业执照/手持身份证：">
                    <el-image fit="cover" :src="lookinfo.license" :preview-src-list="[lookinfo.license]"></el-image>
                </el-form-item>
                <el-form-item label="商户门头照：">
                    <el-image fit="cover" v-for="(item, index) in lookinfo.shop_logos" :key="index" :src="item"
                        :preview-src-list="lookinfo.shop_logos"></el-image>
                </el-form-item>
                <el-form-item label="卫生许可证：">
                    <el-image fit="cover" :src="lookinfo.hygiene_license"
                        :preview-src-list="[lookinfo.hygiene_license]"></el-image>
                </el-form-item>
                <el-form-item label="收款开户行：">
                    <div>{{ lookinfo.deposit_account }}</div>
                </el-form-item>
                <el-form-item label="开户许可证/收款银行卡：">
                    <el-image fit="cover" v-for="(item, index) in lookinfo.deposit_bank" :key="index" :src="item"
                        :preview-src-list="lookinfo.deposit_bank"></el-image>
                </el-form-item>
            </el-form>
            <!--<div class="see-image">
                <div v-if="typeof image === 'object'">
                    <el-image v-for="(item, index) in image" fit="cover" :key="index" :src="item"
                        :preview-src-list="image"></el-image>
                </div>
                <div v-else>
                    <el-image fit="cover" :key="index" :src="image" :preview-src-list="[image]"></el-image>
                </div>
            </div>-->
        </el-dialog>
        <el-dialog title="审核" :visible.sync="showAudit" width="650px">
            <el-form ref="form" :model="auditForm" :rules="rules" label-width="170px">
                <el-form-item label="审核结果：" v-if="auditForm.status != 3">
                    <el-radio-group v-model="auditForm.status">
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="-2">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="小程序支付APPID：" prop="app_id" v-if="auditForm.status == 1 || auditForm.status == 3">
                    <el-input v-model="auditForm.app_id"></el-input>
                </el-form-item>
                <el-form-item label="小程序支付Secret：" prop="secrect" v-if="auditForm.status == 1 || auditForm.status == 3">
                    <el-input v-model="auditForm.secrect"></el-input>
                </el-form-item>
                <el-form-item label="到店付APPID：" prop="pay_app_id" v-if="auditForm.status == 1 || auditForm.status == 3">
                    <el-input v-model="auditForm.pay_app_id"></el-input>
                </el-form-item>
                <el-form-item label="到店付Secret：" prop="pay_secrect" v-if="auditForm.status == 1 || auditForm.status == 3">
                    <el-input v-model="auditForm.pay_secrect"></el-input>
                </el-form-item>
                <el-form-item label="驳回原因：" prop="refuse_reason" v-if="auditForm.status == -2">
                    <el-input v-model="auditForm.refuse_reason" type="textarea" :rows="5"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAudit = !1">取 消</el-button>
                <el-button type="primary" @click="confirmAudit">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="发货" :visible.sync="showSend" width="650px">
            <el-form ref="formSend" :model="sendForm" :rules="rulesSend" label-width="100px">
                <el-form-item label="快递公司：" prop="express_id">
                    <el-select v-model="sendForm.express_id" filterable placeholder="请搜索">
                        <el-option v-for="item in logisticsList" :key="item.id" :label="item.company_name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="快递单号：" prop="express_no">
                    <el-input v-model="sendForm.express_no"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showSend = !1">取 消</el-button>
                <el-button type="primary" @click="confirmSend">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="物流信息" :visible.sync="showLogistics" width="650px">
            <p style="font-size: 16px; font-weight: 500; margin-bottom: 15px">{{ logisticsInfo.company_name }}：{{
                logisticsInfo.nu }}</p>
            <el-timeline :reverse="reverse">
                <el-timeline-item v-for="(item, index) in logisticsInfo.data" :key="index" :timestamp="item.time">
                    {{ item.context }}
                </el-timeline-item>
            </el-timeline>
        </el-dialog>
        <el-dialog title="驳回原因" :visible.sync="showReject" width="650px">
            {{ refuse_reason }}
        </el-dialog>
    </el-main>
</template>

<script>
import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import { mapState } from 'vuex';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            searchForm: {
                page: 1,
                rows: 10,
                sn: '',
                name: '',
                mobile: '',
                start_time: '',
                end_time: '',
                status: '',
            },
            auditForm: {
                id: '',
                status: 1,
                app_id: '',
                secrect: '',
                pay_app_id: '',
                pay_secrect: '',
                refuse_reason: '',
            },
            rules: {
                app_id: { required: true, message: '请填写小程序支付APPID', trigger: 'blur' },
                secrect: { required: true, message: '请填写小程序支付Secret', trigger: 'blur' },
                pay_app_id: { required: true, message: '请填写到店付APPID', trigger: 'blur' },
                pay_secrect: { required: true, message: '请填写到店付Secret', trigger: 'blur' },
                refuse_reason: { required: true, message: '请填写驳回原因', trigger: 'blur' },
            },
            sendForm: {
                id: '',
                express_id: '',
                express_name: '',
                express_no: '',
            },
            rulesSend: {
                express_id: { required: true, message: '请选择快递公司', trigger: 'blur' },
                express_no: { required: true, message: '请填写快递单号', trigger: 'blur' },
            },
            showAudit: !1,
            image: [],
            showImage: !1,
            showSend: !1,
            showLogistics: !1,
            showReject: !1,
            logisticsInfo: {},
            list: [],
            total: 0,
            refuse_reason: '',
            lookinfo: ''
        };
    },
    computed: {
        ...mapState({
            logisticsList: state => state.order.logisticsList,
        }),
    },
    watch: {
        showSend (val) {
            if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
        },
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat (val) {
            return getDateformat(val);
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                sn: '',
                name: '',
                mobile: '',
                start_time: '',
                end_time: '',
                status: '',
            };
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
            } else {
                this.searchForm.page = val;
            }
            this.getList();
        },
        logisticsQuery (id) {
            this.$axios.post(this.$api.order.logisticsQuery, { id: id }).then(res => {
                if (res.code == 0) {
                    this.showLogistics = !0;
                    res.result.info.company_name = res.result.company_name;
                    this.logisticsInfo = res.result.info;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        send (row) {
            this.sendForm = {
                id: row.id,
                express_id: '',
                express_name: '',
                express_no: '',
            };
            this.showSend = !0;
        },
        confirmSend () {
            this.$refs.formSend.validate(valid => {
                if (valid) {
                    let express = this.logisticsList.filter(item => item.id == this.sendForm.express_id);
                    this.sendForm.express_name = express[0].company_name;
                    this.$axios.post(this.$api.serviceProvider.payAuditSend, this.sendForm).then(res => {
                        if (res.code == 0) {
                            this.$message.success('发货成功');
                            this.getList();
                            this.showSend = !1;
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        audit (row) {
            this.auditForm = {
                id: row.id,
                status: 1,
                app_id: '',
                secrect: '',
                pay_app_id: '',
                pay_secrect: '',
                refuse_reason: '',
            };
            this.showAudit = !0;
        },
        auditEdit (row) {
            this.auditForm = {
                id: row.id,
                status: 3,
                app_id: row.app_id,
                secrect: row.secrect,
                pay_app_id: row.pay_app_id,
                pay_secrect: row.pay_secrect,
                refuse_reason: row.refuse_reason,
            };
            this.showAudit = !0;
        },
        confirmAudit () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let data = {
                        id: this.auditForm.id,
                        status: this.auditForm.status,
                    };
                    if (data.status == 1 || data.status == 3) {
                        data.app_id = this.auditForm.app_id;
                        data.secrect = this.auditForm.secrect;
                        data.pay_app_id = this.auditForm.pay_app_id;
                        data.pay_secrect = this.auditForm.pay_secrect;
                    }
                    if (data.status == -2) data.refuse_reason = this.auditForm.refuse_reason;
                    this.$axios.post(this.$api.serviceProvider.payAuditCheck, data).then(res => {
                        if (res.code == 0) {
                            this.$message.success('处理成功');
                            this.getList();
                            this.showAudit = !1;
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.serviceProvider.payAudit, searchForm).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    for (let i in list) {
                        if (list[i].deposit_bank) list[i].deposit_bank = list[i].deposit_bank.split(',');
                    }
                    this.list = list;
                    this.total = res.result.total;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .see-image {
        .el-image {
            width: 140px;
            height: 90px;
            margin-right: 10px;
        }
    }
}
</style>